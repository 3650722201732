import React, { useEffect } from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";
import { useMedia } from "use-media";
import { GoToPopup } from "../homepage";
import { CookiesPoup } from "../homepage";
import workativ_assistant_full from "../../assets/images/popup/popup_assistant_logo.svg";
import wlogo from "../../assets/images/workativ-logo.png";

const countOne = require("../../assets/img/home_page_assist/count_one.svg");
const countTwo = require("../../assets/img/home_page_assist/count_two.svg");
const countThree = require("../../assets/img/home_page_assist/count_three.svg");
const countFour = require("../../assets/img/home_page_assist/count_four.svg");
const clientLogo = require("../../assets/img/feature/home/client_logo_mar24.svg");
const clientLogoMobile = require("../../assets/img/feature/home/client_logo_mar24_mob.svg");
const gabriel = require("../../assets/img/feature/home/landing_client_1.png");
const goto = require("../../assets/img/home_page_assist/goto_logo_paid_lan.svg");
const goto_logo = require("../../assets/images/testimonials/goto_logo_mob.svg");
const goto_manager = require("../../assets/images/testimonials/goto_manager_1.png");
const quotes = require("../../assets/images/testimonials/quote.png");
const star_image = require("../../assets/images/testimonials/star.svg");
const capterra_logo = require("../../assets/images/testimonials/capterra_logo.svg");
const g2_logo = require("../../assets/images/testimonials/g2_logo.svg");
const quotes_star = require("../../assets/images/testimonials/quotes.svg");
const quotes_testi = require("../../assets/images/testimonials/demo_quotes.svg");

const OgImage = require("../../assets/img/home_page_assist/home_header_assist.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  name: "Workativ",
  url: "https://workativ.com",
  description:
    " Workativ Assistant is easy to use 'AI + Automation' SaaS product for companies to build an intelligent Virtual Assistant/Chatbot with pre-built automations, to provide employee support, reduce Help Desk costs, and increase productivity. No coding required! ",
  sameAs: [
    "https://www.youtube.com/channel/UCDNRPFuv8tI7XDm117XV9eg",
    "https://twitter.com/workativ",
    "https://www.facebook.com/workativ",
  ],
  sourceOrganization: {
    "@type": "Organization",
    name: "Workativ Software Solutions",
    description:
      " Workativ offers an intelligent AI + Automation platform to build and deploy purpose-built Chatbot for workplace support. ",
    url: "https://www.workativ.com",
    logo: "https://workativ.com/workativ-logo.196a2d6c.png",
    founder: {
      "@type": "Person",
      name: "Imran Quraishy",
    },
    sameAs: ["https://www.linkedin.com/company/workativ-com/"],
  },
};

export default function DemoPage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Free Conversational AI Chatbot Demo | Workativ Assistant Demo"
        description="Free Conversational AI Chatbot Demo. Learn how workativ can transform your workplace support using conversational ai and workflow automation in minutes."
        keywords={["workativ"]}
        ogTitle="Free Conversational AI Chatbot Demo | Workativ Assistant Demo"
        ogImage={OgImage}
        ogDescription="Free Conversational AI Chatbot Demo. Learn how workativ can transform your workplace support using conversational ai and workflow automation in minutes."
      />
      <div className="book-demo-paid-landing-parent">
        <Container>
          <TopSectionWideBanner />
          <div className={`float-left w-100 ${isMobile ? "mb-4" : "mb-5"}`}>
            <div className="container mb-2">
              <h5
                className={`text-center font-section-normal-text-medium-customer-banner ${
                  isMobile ? "mb-3" : "margin-bottom-20 "
                }`}
                style={{ color: "#2C67F6" }}
              >
                Join leading enterprises that trust Workativ
              </h5>{" "}
              <img
                src={isMobile ? clientLogoMobile : clientLogo}
                alt="Client Logo"
                className={`${!isMobile ? "w-90 m-auto d-flex" : ""}`}
              />
            </div>
          </div>
          <RatingGoTo />
          <Footer />
        </Container>
      </div>
    </>
  );
}

export function TopSectionWideBanner() {
  const isSmall = useMedia({ maxWidth: "520px" });
  React.useEffect(() => {
    const script = document.createElement("script");
    if (document) {
      script.type = "text/javascript";
      script.src = "https://assets.calendly.com/assets/external/widget.js";
      script.async = true;
      document.body.appendChild(script);
    }
    return () => {
      if (document) {
        document.body.removeChild(script);
      }
    };
  }, []);

  const calendlyWidgetStyles = {
    minWidth: "320px",
    height: "700px",
  };
  const formatDate = (date) => {
    var d = new Date(date);
    d.setDate(d.getDate() + 1);

    var month = "" + (d.getMonth() + 1);
    var day = "" + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  return (
    <section className="width-100 float-left demo-wide-head-section-landing">
      <div className="container">
        <a href="https://workativ.com/conversational-ai-platform">
          <img
            src={workativ_assistant_full}
            className="w_assistants_logo_demo_lan"
          />
        </a>
        <div className="flex-container-wide-landing">
          <div className="demo-content-box-wide-landing">
            <span className="font-section-normal-text-medium color-sky">
              BOOK A 30-MINUTE DEMO
            </span>

            <h1
              className={`mb-0 ${
                isSmall ? "font-section-header" : "font-page-header-automate "
              }`}
            >
              See Workativ’s 4-in-1 Gen AI support automation platform in action
            </h1>
            <div className="demo-wide-landing-number-box">
              <span className="demo-wide-landing-number">
                <img src={countOne} />{" "}
                <span
                  className={`color-black ${
                    isSmall
                      ? "font-section-sub-header-slider"
                      : "font-section-sub-header-smaller-blog "
                  }`}
                >
                  Conversation AI Platform<sup>+ LLM</sup>
                </span>
              </span>
              <span className="demo-wide-landing-number">
                <img src={countTwo} />{" "}
                <span
                  className={`color-black ${
                    isSmall
                      ? "font-section-sub-header-slider"
                      : "font-section-sub-header-smaller-blog "
                  }`}
                >
                  App Workflow for Bot
                </span>
              </span>
              <span className="demo-wide-landing-number">
                <img src={countThree} />{" "}
                <span
                  className={`color-black ${
                    isSmall
                      ? "font-section-sub-header-slider"
                      : "font-section-sub-header-smaller-blog "
                  }`}
                >
                  Knowledge AI GPT
                </span>
              </span>
              <span className="demo-wide-landing-number">
                <img src={countFour} />{" "}
                <span
                  className={`color-black ${
                    isSmall
                      ? "font-section-sub-header-slider"
                      : "font-section-sub-header-smaller-blog "
                  }`}
                >
                  AI-powered Live Chat Inbox
                </span>
              </span>
            </div>
          </div>
          <div className="image-box-wide-landing">
            {isSmall ? (
              <div
                className="calendly-inline-widget"
                data-url="https://calendly.com/workativ/workativ-demo-clone"
                style={calendlyWidgetStyles}
              ></div>
            ) : (
              <div
                className="calendly-inline-widget"
                data-url={`https://calendly.com/workativ/workativ-demo-clone?date=${formatDate(
                  new Date()
                )}`}
                style={calendlyWidgetStyles}
              ></div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export function Footer() {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left footer__landing">
      <div className="container">
        <div
          className={`d-flex justify-space-between align-items-center ${
            isMobile ? "flex-column" : ""
          }`}
        >
          <a href="https://workativ.com/conversational-ai-platform">
            <img
              src={workativ_assistant_full}
              className="w_assistants_logo_demo_lan"
            />
          </a>
          <h6
            className={`font-section-small-signup-form mb-0 ${
              isMobile ? "w-100 mt-2 text-align-center" : ""
            }`}
          >
            Workativ Copyrights © <span>{new Date().getFullYear()}</span>. All
            rights reserved.
          </h6>
        </div>
      </div>
    </section>
  );
}

export function RatingGoTo() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section
      class={`testimonial_background_main_container testimonial_background_rating_padding_mobile rating_goto_new float-left ${
        isSmall ? "pt-40" : "p-50"
      }`}
    >
      <div className="container">
        <div className="row">
          <div className={`rating_box_main_container ${isSmall ? "mb-5" : ""}`}>
            <div className="rating_left_box">
              <div className="rating_star_box">
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <span className="font-section-small-text">5/5</span>
              </div>
              <p className="font-section-normal-text-testimonials rating_paragraph_content line-height-18">
                <span>
                  <img
                    src={quotes_star}
                    className="quotes_image_testimonial"
                  ></img>
                </span>
                It just seems to work out of the box. If you have a decent
                outline of the needs the bot needs to fill it is really
                straightforward to set up and test. Truly self service.
              </p>

              <img className="capterra_logo_left" src={capterra_logo} />
            </div>
            <div className="rating_right_box">
              <div className="rating_star_box">
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <span className="font-section-small-text">5/5</span>
              </div>
              <p className="ffont-section-normal-text-testimonials rating_paragraph_content line-height-18">
                <span>
                  <img
                    src={quotes_star}
                    className="quotes_image_testimonial"
                  ></img>
                </span>
                The deployment and support team was exceptional! They worked
                hard to make sure our rollout was successful. When I had
                concerns they worked real hard to make sure they were addressed
                and resolved.
              </p>
              <img src={g2_logo} className="g2_logo_right"></img>
            </div>
          </div>

          {isSmall ? (
            <div className="right-box-testi-lan mt-4">
              <div
                className="testi-box-lan"
                style={{
                  backgroundColor: "#C6FFDD",
                }}
              >
                <img src={quotes} className="quotes__testi__lan" />
                <div className="testi-box-top">
                  <p
                    className={`custom__lineheight text-align-center color-black mt-3 mb-0 pb-0 ${
                      isSmall
                        ? "font-text-medium-goto-solution"
                        : "font-section-small-text-medium-apps "
                    }`}
                  >
                    After researching 5 different chatbot solutions, we decided
                    to go ahead with Workativ for its’ easy integration with
                    systems that we use internally at GoTo.
                  </p>
                </div>
                <div className="testi-box-bottom">
                  <img
                    src={gabriel}
                    className="client__image__testi__lan mb-3"
                  />
                  <h4
                    className="font-section-sub-header-smaller-bold-testimonials mb-1"
                    style={{
                      color: "#4F75D9",
                    }}
                  >
                    Gabriel Grecco
                  </h4>
                  <h5 className="font-section-small-text mb-0">
                    Global Manager - IT Service Desk
                  </h5>
                  <img
                    src={goto}
                    className="client__logo__image__testi__lan mt-3"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="demo__testimonial">
              <div className="demo__testimonial__left">
                {" "}
                <h2 className="font-section-sub-header-big-landing">
                  Why
                  <br /> brands
                  <br /> love
                  <br />
                  <span
                    style={{ color: "#2368F6" }}
                    className="font-section-sub-header-big-landing-bold"
                  >
                    Workativ?
                  </span>
                </h2>
              </div>
              <div className="demo__testimonial__right">
                <div className="demo__testimonial_right__subleft">
                  <p className="font-section-normal-text line-height-18">
                    <span>
                      <img
                        src={quotes_testi}
                        className="quotes__demo__testi"
                      ></img>
                    </span>
                    After researching 5 different chatbot solutions, we decided
                    to go ahead with Workativ, for its’ easy integration with
                    systems that we use internally at GoTo. Workativ platform’s
                    advanced chatbot, app workflows, and deep Slack integration,
                    is a perfect fit for employee support automation.{" "}
                  </p>
                  <p className="font-section-normal-text line-height-18">
                    All the feedback that we passed to the team, was directly
                    turned into new functionalities in a matter of days! And we
                    felt supported throughout the entire onboarding process.
                  </p>
                  <p className="font-section-normal-text line-height-18 mb-0 pb-0">
                    The number of automation possibilities is immense! We can
                    empower our employees to self-service on tasks that were
                    currently handled by IT techs.
                  </p>
                </div>
                <div className="demo__testimonial_right__subright">
                  <img
                    src={goto_manager}
                    className="demo__testimonial-image__manager"
                  />
                  <h3
                    className="font-section-sub-header-smaller-bold-testimonials mb-1"
                    style={{ color: "#4F75D9" }}
                  >
                    Gabriel Grecco{" "}
                  </h3>
                  <h4 className="font-section-normal-text-medium-small color-black">
                    Global Manager - IT Service Desk
                  </h4>
                  <img
                    src={goto_logo}
                    className="demo__testimonial-image__goto"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
